.drop-zone {
	border: 2px dashed var(--color-neutral-300);

	svg {
		color: var(--color-neutral-300);
	}

	&.accepted-file {
		border: 1px solid var(--color-success-700);
	}

	&.rejected-file {
		border: 1px solid var(--color-danger-700);

	}
	
	.close-button {
		top: 0;
	}

	.accepted-files {
		h6 {
			font-weight: 600;
		}
		h6,
		span {
			font-size: 0.75rem;
		}
	}

	.rejected-file-message {
		font-size: 0.75rem;
		color: var(--color-danger-700);
		word-break: break-word;
		height: 1.25rem;
	}
}

.unselected-file {
	&:hover {
		cursor: pointer;
	}
}
