.esignature-option {
  &:hover {
    cursor: pointer;
    background-color: var(--color-neutral-50);
  }

  p {
    color: var(--color-neutral-1000-alpha-6);
    font-size: 0.875rem;
  }

  strong {
    color: var(--body-text-color);
  }

  input {
    display: none;
  }
}

.disabled {
  background-color: var(--color-neutral-50);
  cursor: auto !important;

  strong {
    color: var(--color-neutral-500);
  }

  p {
    color: var(--color-neutral-500);
  }

  img {
    opacity: 0.6;
  }
}

.selected {
  border-color: var(--color-primary-600) !important;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
}
