table {
  width: 100%;
  border-collapse: collapse;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.table-body {
  td {
    padding: 0.75rem 0;
    div {
      position: relative;
    }
    .text-primary {
      width: 30rem;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      cursor: default;

      &:hover + .tooltip {
        opacity: 1;
        top: 25px;
        background-color: rgba(0,0,0,0.8);
        color: #FFFFFF;
        padding: .25rem;
        &:before {
          border-left: .35rem solid transparent;
          border-right: .35rem solid transparent;
          border-bottom: .35rem solid rgba(0,0,0,0.8);
          font-size: 1.25rem;
          position: absolute;
          bottom: 100%;
          left: .5rem;
          content: "";
      }
      }
      @media only screen and (max-width: 30rem) {
        width: 10rem;
      }
    }
  }

  td:last-child div {
      cursor: pointer;
  }
  td:last-child {
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  tr:hover {
    background-color: var(--color-neutral-100);
  }
}
@media only screen and (max-width: 576px) {
  table,
  tbody {
    display: block;
  }
  thead {
    display: none;
  }
  table tr {
    display: flex;
    flex-direction: column;
  }
  table tbody tr td:first-child .text-muted {
    font-size: 0.85rem;
  }
  .table-body td {
    padding: 0.25rem;
  }
}

.table-body td:last-child div > div {
  min-width: 48px;
  overflow: visible;
}
.table-body td:last-child div > div:first-child:last-child {
  min-width: 100%;
}

.badge.badge-info {
  background-color: var(--color-info-50);
  color: rgba(51, 51, 51, 0.8);
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.5em;
  border: 1px solid var(--color-info-700);
}
.fetching {
  position: fixed;
  width: 100%;
  bottom: 25px;
  left: 0;
}