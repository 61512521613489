.loader:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1800ms infinite;
  animation: ellipsis steps(4, end) 1800ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 15px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 15px;
  }
}

.retry .btn {
  font-size: 1.5rem;
}
.retry {
  color: #dddddd;
  background-color: #fcfcfc;
}
label.fileLabel {
  display: block;
}
input.fileTitle {
  width: 100%;
  outline: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--color-neutral-500);
}
input.fileTitle:focus {
  border-color: var(--color-primary-400);
}
input.fileTitle.invalid {
  border-color: #a20011;
  outline-color: #a20011;
}
p.invalid {
  min-height: 1.5rem;
  color: var(--color-danger-500);
  font-size: .875rem;
}
