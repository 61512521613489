.assign-modal {
  // styles to make scrollbar appear on the edge of the modal
  .bx--modal-container-body {
    padding: 2.5rem 0;
  }

  .bx--modal-container {
    padding: 2.5rem 0;

    header {
      margin: 0 2.5rem 2rem 2.5rem;
    }

    footer {
      padding: 0 2.5rem;
    }

    .bx--modal-content {
      padding: 0 2.5rem;
    }
  }

  #subject-input {
    z-index: inherit;
  }
  .selectEmployees {
    min-height: 250px;
  }
}