.responsive.tablehead {
  tr {
    border-bottom: 1px solid var(--color-neutral-300);
  }
  th {
    line-height: 1.25rem;
    color: var(--color-neutral-500);
    padding-bottom: .875rem;
  }
 
}
