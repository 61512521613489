body {
  padding: 2rem 2rem 0 2rem;
  background-color: transparent;
}
.circle-button {
  background-color: rgba(255, 255, 255, 0);
  width: 3rem;
  height: 3rem;
  border-radius: 500px !important;
  border: none;
  color: var(--color-neutral-1000-alpha-5);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-neutral-1000-alpha-1);
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.text-primary {
  color: var(--color-primary-700) !important;
}

.required:after {
  content: '*';
  margin-left: 0.15rem;
  display: inline-block;
  color: var(--color-danger-700);
}

.form-label {
  color: #434343;
  font-size: 0.875rem;
}

// required css changes to resolve visual & functional
// bugs with the design system Modal component
// TODO: remove when Modal component is fixed in design system
.bx--modal .bx--modal-container {
  padding: 0;
}
.bx--modal-container-body {
  padding: 2.5rem;
  cursor: default;
  overflow-y: auto;
}

.btn-download {
  &.loading,
  &:active {
    animation: bounce 0.5s infinite alternate;
    animation-iteration-count: infinite;
    > svg {
      color: var(--color-primary-600) !important;
    }
  }
  @keyframes bounce {
    from {
      transform: translateY(8px);
    }
    to {
      transform: translateY(0);
    }
  }
}

.ac-toast-group {
  position: fixed;
}
.asure-portal-app {
  background-color: var(--color-neutral-0);
  padding: 2rem 2rem 0 2rem;
}
