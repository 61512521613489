.text-area {
  .invalid~textarea {
    border-color: var(--color-danger-700);
  }

  .invalid~textarea:focus {
    border-color: var(--color-danger-700);
    box-shadow: 0px 0px 4px 2px rgba(162, 0, 17, 0.25);
  }

  textarea {
    font-size: 0.875rem;
    border: 1px solid #ccc;
    color: black;
    outline: none;
    padding: .5rem;
    resize: none;

    &::placeholder {
      color: #bbb;
    }

    &:focus {
      border-color: var(--color-primary-700);
      box-shadow: 0px 0px 4px 2px rgba(0, 108, 147, 0.25);
      outline: none;
    }
  }
}